.portfolio__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    padding: 1.3rem;
    border-radius: 2rem;
    background: var(--color-bg-variant);
    transition: 0.5s;
}
.portfolio__item:hover{
    border-color: var(--color-primary-variant);
    background: transparent;
    cursor: pointer;
}
.portfolio__item-photo {
    width: 100%;
    border-radius: 0.5rem;
    overflow: hidden;
    height: 250px;
    aspect-ratio: 4 / 3;
    width: 100%;
    height: auto;
}
.portfolio__item-photo img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.portfolio__item p{
    margin: 1.2rem 0 2rem;
    min-height: 54px;
}

@media screen and (max-width: 1023px) {
    .portfolio__list {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 576px) {
    .portfolio__list {
        grid-template-columns: inherit;
    }
    .portfolio__item p{
        min-height: inherit;
    }
}