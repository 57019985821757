.interactive-container-image {
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity .5s, transform .5s;
}
.interactive-container {
    position: relative;
    height: calc(100vh - 90px);
    max-height: calc(537px - 90px);
    overflow: auto;
}
.content {
    padding-top: 0;
}
.interactive-container-svg {
    transition: opacity .5s, transform .5s;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    text-align: left;
    width: 100%;
    height: 869.107px;
    opacity: 100;
    transform: scale(1);
}
.interactive-container-svg svg {
    left: 0;
    position: absolute;
    z-index: 2;
}
.rpt-tooltip {
    width: 100%;
    max-width: 300px;
    display: none;
    position: fixed;
    top: auto;
    z-index: 10;
    left: 0;
    background:#fff;
    border-radius: 7px;
    box-shadow: -7px 0 33px rgba(57,70,91,.23);
    padding: 10px 15px;
    pointer-events: none;
    transition: .2s ease-out;
    transition-property: margin-top, margin-left, opacity;
    color: #fff;
}
.rpt-tooltip-interactive {
    margin-left: 13px;
    padding: 0 20px;
}
html.dark-theme .rpt-tooltip {
    background: #232323;
}
.rpt-tooltip:before {
    content: "";
    position: absolute;
    top: 0;
    z-index: -1;
    left: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 30px 40px 0;
    border-color: transparent #fff transparent transparent;
    -webkit-transform: rotate(1turn);
    top: auto;
    bottom: 0;
    border-width: 0 0 40px 30px;
    border-color: transparent transparent #232323 transparent;
}
.rpt-tooltip-interactive-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 50px;
    border-bottom: 1px solid #303030;
    font-size: 14px;
    color: #bbb;
}
.rpt-tooltip-interactive-body {
    display: flex;
    min-height: 50px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #303030;
}
.rpt-tooltip-interactive-footer {
    display: flex;
    flex-direction: column;
    padding: 9px 0;
}
.justify-content-between {
    justify-content: space-between !important;
}
.rpt-tooltip-interactive-header .title {
    flex-shrink: 0;
    font-size: 18px;
    color: #fff;
}
.me-2 {
    margin-right: 0.5rem !important;
}
.rpt-tooltip-interactive-footer-row {
    padding: 4px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.rpt-tooltip-interactive-footer-row .estate {
    color: #bbb;
    font-weight: 400;
    font-size: 14px;
    width: 50px;
}
.rpt-tooltip-interactive-footer-row .count {
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    width: 50px;
}
.rpt-tooltip-interactive-footer-row .price {
    margin-left: auto;
    color: #1472fe;
    font-size: 16px;
    font-weight: 600;
}
.interactive-container-svg svg polygon{
    transition: 0.5s;
}
.interactive-container-svg svg polygon:hover{
    stroke: #fff;
}