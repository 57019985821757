body {
    background: #edf2f7;
}

details {
    display: block;
    background: #eeeeee;
    width: 400px;
    box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 1.5rem;
}

summary::-webkit-details-marker {
    display: none;
}

summary::-moz-list-bullet {
    list-style-type: none;
}

summary::marker {
    display: none;
}

summary {
    display: block;
    padding: .3em 1em .3em .9em;
    border-bottom: 1px solid #e2e8f0;
    font-size: 1.4em;
    cursor: pointer;
    position: relative;
}

summary:before {
    top: .4em;
    right: .3em;
    color: transparent;
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTYuNTkgOC41OUwxMiAxMy4xNyA3LjQxIDguNTkgNiAxMGw2IDYgNi02eiIvPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz48L3N2Zz4=") no-repeat 50% 50% / 1em 1em;
    width: 1em;
    height: 1em;
    content: "";
    position: absolute;
    transition: transform .5s;
}

details[open] > summary:before {
    transform: scale(1, -1);
}

summary ~ * {
    padding: 0 1em 10px 1.4em;
}

details[open] summary ~ * {
    animation: sweep .5s ease-in-out;
}

@keyframes sweep {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

summary:focus {
    outline: 0;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3), inset 0 0 2px rgba(0, 0, 0, 0.3);
}

.experience__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.experience__container > div {
    background: var(--color-primary-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
}

.experience__container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.experience__container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

.experience__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
}

.experience__details {
    display: flex;
    gap: 1rem;
}
.experience__details svg{
    width: 24px;
    height: 24px;
    margin-top: 0.4rem;
}

@media screen and (max-width: 1023px) {
    .experience__container {
        grid-template-columns: inherit;
    }
}

@media screen and (max-width: 767px) {
    .experience__container > div {
        padding: 1.5rem;
    }
}